
.landing {
	position: relative;
	width: $width;
	height: $height;
	color: white;
	&--header {
		border-top: 2px solid #B8E986;
		position: absolute;
		top: 0;
		width: 650px;
		height: 291px;
		background: rgba($green,.5);
		background: $green;
		padding: 30px 0 0 85px;

		h1 {
			color: white;
			text-transform: lowercase;
			font-style: italic;
			font-weight: normal;
			font-size: 50px;
			line-height: 66px;
			margin: 0;
			em {
				text-decoration: underline;
			}
			strong {
				display: block;
				font-size: 100px;
				font-style: normal;
			}
		}
		p {
			line-height: 1.4;
			margin-top: 30px;
		}

	}


	&--branding {
		position: absolute;
		left: 0;
		bottom: 0;
	}

	&--nav {
		position: absolute;
		left: 650px;
		width: 1024 - 650px;
		height: 291px;
		background: rgba($blue, .5);
		background: $blue;
		font-size: 12px;
		font-style: normal;
		padding: 40px 0 0 110px;
		margin: 0;

	}
	.landing--instructions {
		@extend %list-reset;
		margin-bottom: 28px;

		li {
			margin: 12px 0;
		}
	}


	&--instructions {
		margin: 0;
		list-style: none;
	}

	&--start {
		display: block;
		font: bold 22px/1 "Roboto Slab", sans-serif;
		text-decoration: none;
		text-transform: uppercase;
		padding: 10px 0 0 25px;
		transition: .3s;
		position: relative;

		strong {
			display: block;
			font-size: 1.45em;
		}

		&:link,&:visited {
			color: #fff;
		}

		&:hover {
			.landing--start-bg {
				opacity: .5;
			}
		}
	}

	&--start-label {
		position: absolute;
		top: 0;
		left: 0;
		padding: 10px 0 0 25px;
	}

	&--start-bg {
		position: absolute;
		width: 169px;
		height: 77px;
		top: 0;
		left: 0;
		transition: .3s;
	}
	&--pagecurl {
		position: absolute;
		top: 0;
		// display: none;
		// outline: 1px solid red;
		display: block;
		left: 550px;
		width: 205px;
		height: 337px;
	}
}

.instr-icon {
	width: 19px;
	height: 19px;
	display: inline-block;
	vertical-align: middle;
	margin: 0 .5em 0 0;
}
