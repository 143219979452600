
.text {
	max-width: 768px/3;

	h1,h2,h3,h4,h5,h6 {
		margin: 0;
		& + p {
			margin-top: 0;
		}
	}

	*:first-child {
		margin-top: 0;
	}
	*:last-child {
		margin-bottom: 0;
	}

}
.text--shadow {
	text-shadow: 1px 1px 6px darken($blue,5%);
}

.text--legible {
	background: rgba(#fff,.8);
	padding: 10px 20px;
	border-radius: 8px;

	h1,h2,h3,h4,h5,h6 {
		color: $dkgreen;
	}
}
.text--alt {
	max-width: 768px-320;
	text-align: center;
}
.text--right {
	max-width: 33%;
	text-align: right;
}
.text--rev {
	p {
		color: #fff;
	}
}

.keypt {
	&--btn {
		color: #fff;
		font-weight: bold;
		font-style: normal;
		width: 50px;
		height: 50px;
		padding: 0;
		text-align: center;
		background: $green;
		background: linear-gradient(to bottom, lighten($green, 25%) 0%, lighten($green, 7%) 50%, $green 50%);
		border-radius: 50%;
		border: 4px solid darken($green, 10%);
		position: relative;
		outline: 0;
		box-shadow: 0 0 0px 2px $dkaqua;

		&:hover {
		background: linear-gradient(to bottom, lighten($green, 20%) 0%, lighten($green, 2%) 50%, darken($green, 5%) 50%);
		}
	}

	&--action-btn {
		@extend .keypt--btn;

		background-color: $aqua;
		background: linear-gradient(to bottom, lighten($aqua, 25%) 0%, lighten($aqua, 7%) 50%, darken($aqua, 5%) 50%);
		border-color: $dkaqua;
		box-shadow: none;

		&:hover {
			background-color: $dkaqua;
			background: linear-gradient(to bottom, lighten($aqua, 20%) 0%, lighten($aqua, 2%) 50%, darken($aqua, 5%) 50%);
		}
	}

	&--text {
		width: 350px;
		margin-top: -.5em;
		padding: 20px;
		color: #fff;
		font-weight: normal;
		background: $green;
		border-radius: 8px;
		border: 4px solid darken($green, 5%);
		text-align: center;
		opacity: 1;
		transition: .2s;
	}
	&--right, &--supermodal {
		width: 350px;
		margin: -100px 0 0 30px;
		padding: 20px;
		color: #fff;
		font-weight: normal;
		background: $green;
		border-radius: 8px;
		border: 4px solid darken($green, 5%);
		text-align: center;
		opacity: 1;
		transition: .2s;
	}
	&--modal, &--supermodal {
		width: -40 + 1024px;
		top: 50%;
		transform: translateY(-50%);
		margin-left: -(-40 + 1024px)/2;
		max-height: -40 + 674px;
		position: fixed;
		left: 50%;


		h1,h2,h3,h4,h5,h6 {
			color: #fff;
		}

		&:after {
			position: absolute;
			top: 10px;
			right: 10px;
			content: '×';
			width: 20px;
			height: 20px;
			line-height: 20px;
			font-size: 40px;
			cursor: pointer;
		}
	}
	&--supermodal {
		transform: translate(-50%, -50%);
		text-align: left;
		font-size: 18px;
		border-width: 0;
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 90vh;
		background: #eee;
		color: black;
		padding-top: 50px;
		width: 834px;
		margin: auto;

		.wrapper {
			padding: 0;
		}

		img {
			max-width: 100%;
			height: auto;
		}

		.img-center {
			margin: auto;
			display: block;
		}


		&:before {
			display: block;
			content: '';
			position: absolute;
			height: 50px;
			width: 100%;
			background: linear-gradient(to right, $blue 0%, $blue 50%, $green 50.01%, $green 100%);
			top: 0;
			left: 0;
		}

		&:after {
			top: 14px;
			right: 14px;
			color: $blue;
		}

		h3 {
			color: $blue;
			text-align: center;
			text-transform: uppercase;
			font-size: 44px;
		}
		h4 {
			color: $dkgreen;
			text-align: center;
			font-family: $fancyfont;
			font-style: normal;
			font-size: 26px;
		}

		h5 {
			color: #000;
			text-align: center;
		}

	}
	&--modal.hidden, &--supermodal.hidden {
		top: -100%;
		transform: translateY(-100%);
	}


	&--close {
		cursor: pointer;
		display: inline-block;
		padding: .25em .5em;
		border: 1px solid #fff;
		color: white;
		&:hover {
			background: darken($green, 5%);
		}
	}
}

.img {
	&--center {
		margin-left: auto;
		margin-right: auto;
	}
}
