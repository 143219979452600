// keyframes and animation classes

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes pulsex2 {
  33.3333% {
    transform: scale(1.2);
  }
  66.6666% {
    transform: scale(1);
  }
}

@keyframes pulse2x2 {
  33.3333% {
    transform: scale(1);
  }
  66.6666% {
    transform: scale(1.2);
  }
}

@keyframes bob {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 2px);
  }
  100% {
    transform: translate(0, 0);
  }
}




.bob {
  animation: bob .5s infinite;
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.pulse {
  animation: pulse 1s infinite;
}
.pulse2 {
  animation: pulse2 1.2s infinite;
}
.pulsex2 {
  animation: pulsex2 1.2s infinite;
}
.pulse2x2 {
  animation: pulse2x2 1s infinite;
}
