.kitchen-bg {
	width: 2164px;
	height: $height;
}

.table {
	// background: url(img/table.svg) repeat-x 100% 100%;
	width: 1023 + 500px;
	height: $height;
}
.chairs {
	// background: url(img/chair.svg) repeat-x 100% 101%;
	width: 1023 + 500px;
	// height: $height;
	opacity: .9;
}
