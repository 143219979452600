.d {
	position: absolute;
	display: block;
}


// z-index manager
// edit the $layers list, put your classes in the order you want then to appear on top of each other

@mixin lay-z-index($layers, $z: 0) {
    $len: length($layers);

    @each $layer in $layers {
      $idx: index($layers, $layer);
      #{$layer} {
        z-index: $z + $len - $idx;
      }
    }
}
.browsehappy {
	position: fixed;
	top: 0; left: 0;
	width: 100%;
	background: red;
	margin: 0;
	padding: .5em 1em;
	text-align: center;
}

$layers: (
	".browsehappy"
	"#site-sm-nav"
	".noscroll"
	".marker"
	".top"
	".scollpos"
	".keypt--modal"
	"#modal"
	"#overlay"
	"#start"
	".landing--pagecurl"
	".landing--start"
	".landing--header"
	".landing"
	".path-recycle"
	".path-trash"
	".keypt--btn"
	".keypt--action-btn"
	".text"

	".breakdown"
	".tree"
	".road-sign"
	".garbage-truck"
	".road-sign-back"
	".recycle-end--btn"
	"#recycle-end--theend"
	"#allow-trash-journey"
	".recycle-end"
	".recycle-kitchen-fg"
	".garbage-kitchen-items"
	".recycle-kitchen-bg"
	".map-markers"
	".globe-arrows"
	".plant-arm"
	".plant-recylables"
	".plant-trash-can"
	".plant-recylables-apple"
	".plant-exit"
	".conveyorbelt-group"
	".left-pile"
	".paint-opendoor"
);

$lowerlayers: (
	".butterfly"
	".murfsign"
	".foreground"
	".text--lower"
);

@include lay-z-index($layers, 1000);
@include lay-z-index($lowerlayers, 100);



.hidden {
	opacity: 0;
}

.marker {
	position: absolute;
	padding: 0 .5em .25em;
	font-weight: bold;
	text-shadow: 1px 1px #000;
	// background: rgba(red, .25);
	border-left: 1px solid red;
	color: white;
	bottom: 0;
	font-size: 12px;
}

