.wrapper {
	@include has-column;
	max-width: $hp-bp--desktopb;
	padding-left: $hp-gutter;
	padding-right: $hp-gutter;
	text-align: center; // center your columns
	margin-left: auto;
	margin-right: auto;
}


// preset column classes ala bootstrap, 960gs
@for $i from 1 through 12 {
	.col-#{$i} {
		font-size: $hp-fontsize;
		text-align: left;
		padding-left: $hp-gutter;
		padding-right: $hp-gutter;

		@include media {
			@include column(12,$i);
		}
	}
}
@for $i from 1 through 12 {
	.col-#{$i}__push {
		@include media {
			margin-left: 100% / 12 * $i;
		}
	}
	.padding-top-#{$i} {
		padding-top: $i * 1rem;
	}
	.padding-bottom-#{$i} {
		padding-bottom: $i * 1rem;
	}
}

.col__last {
	margin-right: 100%;
}