// General site layout

.fixed-container {
	position: fixed;
	width: 1024px;
	height: 768px;
	top: 0;
	left: 50%;
	margin-left: -.5 * 1024px;
}

// this is the main container that containts all the parallax/skrollr objects
.parallax {
	position: relative;
	overflow: hidden;
	width: 1024px;
	height: 674px;
	// top: .5 * 68px;
	// left: 50%;
	// margin-left: -.5 * 1024px;
	// box-shadow: 0 0 100px rgba(0,0,0,.5);
}

// site backgrounds
.bg-sky {
	position: fixed;
	width: 100%;
	height: 600px;
	// background: url(img/bg-sky.jpg) no-repeat 50% 0;
	@media (min-height: 779px) {
		background: url(img/landing-sky-bg.jpg) no-repeat 50% 0;
		background-size: cover;
	}
}
.bg-board {
	position: fixed;
	top: 600px;
	width: 100%;
	height: 600px;
	height: calc( 100vh - 600px );
	@media (min-height: 779px) {
		background: url(img/corrugated.jpg) no-repeat 50% 0;
		background-size: cover;
	}
}