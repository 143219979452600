// The buttons for recycling or trash storylines/paths

.path-trash {
	background-color: $orange;
	&:hover {
		background-color: darken($orange,10%);
	}
}

.path-recycle {
	background-color: $green;
	text-align: right;
	&:hover {
		background-color: darken($green,10%);
	}
}

.path-trash, .path-recycle {
	overflow: hidden;
	width: 350px;
	height: $height / 2;
	font-weight: bold;
	text-decoration: none;
	padding: 45px 50px 20px;
	// transform-style: preserve-3d;
	// perspective: 600px;
	transition: background-color 1s;
	color: #fff;
	&:link,&:visited {
		color: #fff;
	}
	// &:hover {
	// transform: rotateY(360deg);
		// img {
		// 	transform: scale(1.3);
		// }
	// }
	img, .svgmask {
		position: relative;
		margin: 10px auto;
		display: block;
		transition: .2s;
	}


}

	.path-dragover {

		&.path-trash {
			background-color: darken($orange, 15%);
		}

		&.path-recycle {
			background-color: darken($green, 15%);
		}

	}