.breakdown {

	// wrapper for desc and year
	&--info {
		@extend .d;
		@extend .h;
		width: 500px;
		top: 0px;
		left: 0px;
		text-align: center;
		color: #fff;

	}

	&--desc {
		@extend .d;
		top: 20px;
		margin-left: 300px;
		width: 500px;
		font: normal bold 24px/1.4 $fancyfont;
		text-shadow: .1em .1em .25em rgba(0,0,0,.5);

		&-alt {
			color: $dkgreen;
			text-shadow: none;
		}
	}


	&--year {
		@extend .d;
		bottom: 200px;
		margin: 0 0 0 150px;
		width: 500px;
		font-size: 2.5em;
		text-shadow: .1em .1em .25em rgba(0,0,0,.5);
		line-height: 1;

		strong {
			display: inline-block;
			vertical-align: middle;
			font-size: 2em;
			font-style: normal;
		}
	}
}

.truck-top {
	top: 115px;
	transition: .5s;

	&--open {
		top: 0;
	}
}