// mobile-first media query
@mixin media($mediawidth: $hp-bp--tablet) {
	@media (min-width: $mediawidth) {
	@content;
	}
}


@mixin container($numberofcolumns: 3, $howmanycolumns: 1) {
	display: inline-block;
	font-size: $hp-fontsize;
	margin-left: 0;
	margin-right: 0;
	vertical-align: top;
	width: 100% / $numberofcolumns * $howmanycolumns;

	text-align: left;

	img {
	max-width: 100%;
	height: auto;
	}
}


//easy columns
@mixin column($numberofcolumns: 3, $howmanycolumns: 1) {
	// Lazyman Columns:
	// - doesn't use floats; uses inline-blocks (@include inblk)
	// - there are no gutters; apply left/right padding if needed
	// - do use left/right margins
	// - use overflow: hidden or clearfix
	// - must declare parent element to use font-size: 0; column font-size is reset to 1rem
	// - *or* remove white space from code

	// Usage:
	// Want a 1/3 column: column(3)
	// Want a 1/4 column: column(4)

	// Want a 2/3 column: column(3, 2)
	// Want a 3/4 column: column(4, 3)
	// - the optional 2nd number is how wide (in columns) you want to use
	// - basically the fraction number orders are reversed

	// - this container is padded with $hp-gutter


	display: inline-block;
	@include container($numberofcolumns, $howmanycolumns);
	padding-left: $hp-gutter;
	padding-right: $hp-gutter;
}

@mixin has-column {
	// @include this on the parent of @column
	// Effectively acts like a .row in other frameworks
	font-size: 0.001px; // can't use 0 because of Ice Cream Sandwich
}
