.menu-icon,  {
	width: 12px;
	height: 11px;
	fill: #fff;
}

// #menu-icon {
// 	fill: red;
// 	g {
// 		fill: red;
// 	}
// }

.site-header, .site-footer {
	// outline: 1px solid red;
	// position: absolute;
	width: 100%;
}

.site-header {
	display: none;
	// @media (min-height: 768px) {
	@media (min-height: 860px) {
		height: 53px;
		display: block;
	}
	@media (min-height: 900px) {
		margin-top: 20px;
	}
}

.main-nav {
	@extend %list-reset;
	display: table-cell;
	position: relative;
	&--home {
		width: 227px;
		top: 0;
		height: 53px;
		display: inline-block;
	}
}

// hamburger menu
// html is in the inc_footer.php
.site-sm-nav {
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	// @media (min-width: 1025px) {
	// 	display: none;
	// }
	// @media (min-height: 769px) {
	// 	display: none;
	// }
	// turn into an overlay
	&.active {
		display: block;
		bottom: 0;
		right: 0;
		background: rgba($blue,.9);
	}

	&--toggle {
		min-width: 40px;
		min-height: 40px;
		background-color: transparent;
		border-width: 0;
		color: #fff;
		font-size: 8px;
		font-style: normal;
		line-height: 1;
		position: fixed;
		top: 4px;
		right: 0;
		text-align: center;
		text-transform: uppercase;

		@media (min-width: 1025px) and (min-height: 767px) {
			display: none;
		}

		strong {
			display: block;
			font-size: 24px;
		}
	}

	&--list {
		$bg: $green;
		@extend %list-reset;
		background: $bg;
		border-radius: 8px;
		color: #fff;
		overflow: hidden;
		position: fixed;
		text-align: center;
		transition: .3s;

		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);

		@media (min-width: 1025px) {
			// display: none;
		}


		li {

		}

		a, .site-sm--list-item {
			display: block;
			line-height: 1;
			padding: 1em 2em;
			text-decoration: none;
			border-bottom: 1px solid darken($bg,5%);

			&:link,&:visited {
				color: #fff;
				font-weight: bold;
			}
		}
	}
}

.site-footer {
	// top: 734px;
	font-size: 12px;
	font-style: normal;
	line-height: 34px;
	font-weight: bold;
	color: #fff;
	background: $blue;
	padding: 0 20px;
	display: table;

	@media (min-width: 1078px) {
		background: transparent;
		padding: 0;
	}

	&--copyright {
		display: table-cell;
	}

	a {
		color: #fff;
		text-decoration: none;

		&:link, &:visited {
			color: #fff;
		}
		&:hover {
			text-decoration: underline;
		}
	}

	&--home {
		display: inline-block;
		margin-right: 2em;
		@media (min-height: 769px) {
			display: none;
		}
	}


}


.footer-icon {
	width: 22px;
	height: 22px;
	display: inline-block;
	vertical-align: -6px;
	margin: 0 5px 0 20px;
	border-radius: 3px;
}

.footer-nav {
	@extend %list-reset;
	text-align: right;
	* {
		display: inline-block;
	}
}