.recycle-bg {
	height: $height;
	background: #000 url(img/suburb-bg.jpg) repeat-x 0 0;
}

.plant-bg {
	height: $height;
	background: url(img/plant-bg.jpg) repeat-x -200px 0;
	position: relative;
	overflow: hidden;
}
.plant-wall-1 {
	position: absolute;
	top: 0;
	left: 0;
}
.plant-wall-2 {
	position: absolute;
	top: 0;
	right: 0;
}
.plant-recycling-pile {
	position: absolute;
}


.map-marker {
	background: rgba($blue,.9);
	color: white;
	padding: .25em .5em .45em;
	font-size: .8em;
	font-weight: bold;
	font-style: normal;
	line-height: 1;

	small {
		border-left: 1px solid white;
		font-style: italic;
		font-weight: normal;
		padding-left: .65em;
		margin-left: .25em;
	}
}
.steelgoods {
	&--default, &--complete {
		transition: 2ms;
		// position: absolute;
		top: 0;
		width: 100%;
	}
}

// I fucking hate last minute changes
#steelgoods--text {
	.hidden {
		display: none;
	}
}

.steelcard {
	width: 238px;
	height: 213px;
	cursor: pointer;

	&--front, &--back {
		position: absolute;
		top: 0;
		left: 0;
		width: 238px;
		height: 213px;
		transition: .25s 0s all ease-in;
	}

	&--front {
		transform: perspective( 1000px ) rotateY(0deg);
		z-index: 101;
	}

	&--back {
		background: $green;
		color: white;
		font-weight: bold;
		font-style: normal;
		text-align: center;
		transform: perspective( 1000px ) rotateY(-180deg);
		z-index: 100;
		opacity: 0;
		padding-top: 1em;

		img, &-img {
			display: block;
			margin: 1em auto 0;
		}
	}

	&--flip &--front {
		transform: perspective( 1000px ) rotateY(180deg);
		z-index: 100;
	}

	&--flip &--back {
		transform: perspective( 1000px ) rotateY(0deg);
		z-index: 101;
		opacity: 1;
	}


	&--btn {
		@extend .keypt--btn;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -20px 0 0 -20px;
		// left: 50%;
		// top: 50%;
		// transform: translate(-50%, -50%);
	}

	// &:hover {
	// 	@extend .steelcard--flip;
	// }
}

.recycle-end {
	position: absolute;
	right: 0;
	top: 0;
	width: 350px;
	height: $height;
	overflow: hidden;
	text-align: center;
	color: white;
	display: table;
	background: $green;


	&--info {
		background: $green;
		display: table-cell;
		vertical-align: middle;
		padding: 2em;
		opacity: 1;
		transition: opacity .3s;
		width: 350px;
		// height: $height;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}

	&--infodisabled {
		// display: block;
		// height: 0;
		// overflow: hidden;
		opacity: 0;
		// display: none;
		.recycle-end--btn-end {
			display: none;
		}
		&#allow-trash-journey {
			display: none;
		}
	}

	&--btn {
		position: absolute;
		@extend .keypt--btn;

		.ico-check {
			display: none;
			margin: 0 auto;
			width: 20px;
			height: 20px;
			fill: #ffffff;
		}
	}

	&--btnalt {
		background-color: $aqua;
		background: linear-gradient(to bottom, lighten($aqua, 25%) 0%, lighten($aqua, 7%) 50%, darken($aqua, 5%) 50%);
		border-color: $dkaqua;
		box-shadow: none;

		&:hover {
			background-color: $dkaqua;
			background: linear-gradient(to bottom, lighten($aqua, 20%) 0%, lighten($aqua, 2%) 50%, darken($aqua, 5%) 50%);
		}

		.ico-quest {
			display: none;
		}

		.ico-check {
			display: block;
		}
	}

	&--btn-end {
		@extend .landing--start;
		padding: 0;



	}

	&--btn-bg {
		@extend .landing--start-bg;
		position: relative;
		width: 300px;
		height: 94px;

		* {
			fill: #000000 !important;
		}

	}

	&--btn-label {
		@extend .landing--start-label;
		width: 250px;
	}


}

