// Global CSS goes here


// http://stackoverflow.com/questions/9807620/ipad-safari-scrolling-causes-html-elements-to-disappear-and-reappear-with-a-dela
// img, svg, object {
	// -webkit-transform: translate3d(0, 0, 0);
// }


.svgmask {
	// have to use object tags for svg masks for ios7-8
	// but this also prevents swipe events
	// so we overlay a div on top of it.
	overflow: none;

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	// * {
	// 	background: transparent !important;
	// 	fill: transparent !important;
	// }

	// object, object * {
	// 	background: transparent !important;
	// }
}

* {
	box-sizing: border-box;
}

html {
	@extend %lighttheme;
	background: #000;
	font: 200 #{$hp-basefontsize}/1.6 $font;
	box-sizing: border-box;
	height: 100%;
}
*, *:before, *:after {
	box-sizing: inherit;
}

[draggable="true"] {
	cursor: move;
}

body {
	min-height: 100%;
	font-size: 18px;
	font-style: italic;
	min-width: 1000px;
}

// @for $i from 1 through 6 {
// 	h#{$i} {
// 		margin: 0;
// 		line-height: 1.1;
// 	}
// }

table {
	border-collapse: collapse;
}

th, td {
	border: 1px solid;
	border-color: inherit;
	padding: .5em 1em;
}


// remove white-space on top, add on bottom:
h1,h2,h3,h4,h5,h6 {
	line-height: 1.1;
	// margin: 0 0 .5rem 0;
	margin: 1rem 0;
	letter-spacing: -.01em;

	a {
	text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

// headline scale
@for $i from 1 through 6 {
	h#{$i} {
		font-size: #{(7 - $i)*.1 * (7 - $i)*.6 + 1}em;
	}
}

// add white-space on top when following content:
// p,ol,ul,dl,table,img,code,pre,blockquote {
// 	margin: .5rem 0;
// 	&+h1,&+h2,&+h3,&+h4,&+h5,&+h6 {
// 		margin-top: 2.5rem;
// 	}
// }

ul {
	padding-left: 1.125em;
}

// test the %darktheme
.alt {
	@extend %darktheme;
	background: #000;
}








h1 {
	color: $green;
	font-size: 36px;
	font-family: "Roboto Slab", sans-serif;
	font-style: normal;
}



img {
	margin: 0;
	height: auto;
}





// IE FIXES

svg:not(:root) { overflow: hidden; }
