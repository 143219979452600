@mixin clearfix() {
	& {
		*zoom: 1;
	}
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin image-replace($imageURL) {
	background: url($imageURL) no-repeat;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

%margins {
	*:first-child {
		margin-top: 0;
	}
	*:first-child {
		margin-bottom: 0;
	}
}

%list-reset {
	margin: 0;
	padding: 0;
	list-style-type: none;

	li {
		margin: 0;
		padding: 0;
	}
}