// .wrapper {
// 	position: relative;
// }

// default class for skrollr objects

.h {
	height: $height;
}

.o {
	outline: 1px solid red;
	width: 100%;
}

.btn {
	line-height: 1;
	text-transform: uppercase;
	padding: .2em 1em .1em;
	background: $green;
	border-radius: 500px;
}


// GENERAL PAGE LAYOUT FOR THE PAGE W/ PARALLAX AREA

.container {
	max-width: 1024px;
	margin: 0 auto;
	@extend %margins;
}




// black background OVERLAY for modals

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(#000,.7);
	cursor: pointer;


	&.hidden {
		height: 1px;
	}

	&--preload {
		// background: #000;

	background: linear-gradient(45deg, $dkgreen, $blue);
	background-size: 400% 400%;
	animation: preloadadmin 4s ease infinite;





		color: #fff;

		&:before {
			content: "loading...";
			position: fixed;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			display: block;
			font-size: 60px;
			text-align: center;
		}
	}
}

@keyframes preloadadmin {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}



// debug
.scrollpos {
	position: fixed;
	background: red;
	color: white;
	padding: 10px 20px;
	bottom: 0;
	left: 0;
}